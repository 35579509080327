.container {
	background-color: var(--surface-primary); /* background-color-gray-5 */
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: var(--text-secondary);
	text-align: center;
	font-size: var(--fs-15);

	width: 100%;
	height: 100%;

	&.hasBorder {
		border-right: 3px solid var(--border-primary); /* border-right-gray-30 */
	}

	&.fullHeight {
		height: 100%;
	}

	.noSignalIcon {
		font-size: 80px;
		margin-bottom: 12px;
		color: var(--icon-tertiary);
	}

	.clearFilterText {
		color: var(--text-primary); /* //color-dark */
		text-decoration: underline;
		cursor: pointer;
	}

	.message {
		padding: 0 8px;
	}
}

.emptyGridContainer {
	overflow-y: auto;
	height: 100%;
	width: 100%;
	overflow-y: auto;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	cursor: initial;
	background-color: var(--surface-primary); /* background-color-gray-5 */
	gap: 12px;
	padding: 12px;

	.emptyIcon {
		overflow: hidden !important;
		color: var(--icon-tertiary); //color--gray-10);
		font-size: 80px;

		@media screen and (max-height: 849px) {
			margin: 5px 0px;
		}
	}

	.emptyStateMessage {
		color: var(--text-secondary);
		font-size: var(--fs-15);
		text-align: center;
	}

	.startTrading_Inactive,
	.startTrading_Active {
		color: var(--text-primary); /* //color-dark */
		text-decoration: none;
		cursor: pointer;
		max-width: none;
	}

	.startTrading_Active:hover,
	.startTrading_Inactive:hover {
		color: var(--text-primary); /* //color-dark */
		cursor: pointer;
		max-width: none;
	}
}

html[dir='rtl'] {
	.container {
		.hasBorder {
			border-right: 0;
			border-left: 3px solid var(--border-primary);
		}
	}
}
