.container {
	background-color: var(--surface-primary); /* background-color-light */
	display: grid;
	width: 100%;
	height: 100%;
	min-width: 1200px;
	grid-template-columns: 60px calc(100% - 60px);
	grid-template-rows: 52px calc(100% - 52px);
	grid-template-areas:
		'header header'
		'navigation	content';
	transition: all ease 0.2s;
}

.detachContainer {
	grid-template-columns: 0;
	grid-template-rows: 0;
}

.detachContainerScroll {
	height: 100vh;
}

.header {
	grid-area: header;
	height: 100%;
	background: var(--surface-primary);
	border-bottom: 1px solid var(--border-primary);

	z-index: 400;
	transition: all ease 0.2s;
}

.navigation {
	grid-area: navigation;
	// z-index: 399;
	width: 60px;
	border-right: 1px solid var(--border-primary);
}

.content {
	grid-area: content;
	z-index: 99;
	overflow: hidden;
}

html[dir='rtl'] {
	.navigation {
		border-left: 1px solid var(--border-primary);
	}
}
