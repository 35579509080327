@import 'src/css/common/_custom_scrollbar';
@import 'src/css/common/_vertically_center';

.newsfeedContainer {
	@extend %custom-scrollbar-element;

	width: 100%;
	height: calc(100% - 130px);
	background-color: var(--surface-primary); /* background-color-gray-5 */
	overflow-y: overlay;
	overflow-x: hidden;
	transition: transform 300ms linear;
	flex-grow: 1;
}

.closeButtonLocation {
	@include vertically-center;
	left: -3px;
}

// Empty State Styling
.empty {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	height: 100%;
	justify-content: center;
	padding: 12px;
	gap: 12px;
}

.emptyIcon {
	color: var(--icon-tertiary); //color--gray-10);
	font-size: 100px;
	font-size: 80px;
}

.message {
	text-align: center;
	font-size: var(--fs-15);
	letter-spacing: -0.36px;
	color: var(--text-secondary);
}

.contentsAndDisclaimer {
	display: flex;
	flex-direction: column;
	height: 32px;
}

html[dir='rtl'] {
	.closeButtonLocation {
		right: -3px;
		left: unset;

		svg {
			transform: rotate(0deg)!important;
		}
	}
}
