.header {
	background-color: inherit;
	width: 100%;
	height: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 0px 8px;
	display: flex;
}

.accountInfoContainer {
	display: flex;
	justify-content: center;
	align-items: baseline;
	min-width: 135px;
	float: right;
}

.headerUnapprovedView {
	@extend .accountInfoContainer;
	margin-right: 8px;
}

.search {
	width: 50%;
}

.smallSearch {
	width: 220px;
}

.type {
	font-size: 13px;
	font-weight: bold;
}

//The account type on the left of the currency icon
.typeLive {
	@extend .type;
	color: var(--mint);
	margin-right: 4px;
}

//The account type on the left of the currency icon
.typeDemo {
	@extend .type;
	color: var(--topaz);
	margin-right: 4px;
}

.accountId {
	color: var(--dark);

	font-size: 13px;
	font-weight: 700;
	margin: 0 5px;
}

.unapprovedAccountId {
	@extend .accountId;
	margin: 0px 5px;
}

.modalSettings {
	width: 463px !important;
}

.modalContent {
	padding: 0px !important;
}

.popupContainer {
	display: flex;
	flex-direction: column;
	width: 285px;
}

// The container which shows if the user is on a live or a demo account and the id
.accountStatus {
	display: flex;
	margin-top: 6px;
	justify-content: center;
}

.popupHeader {
	display: flex;
	justify-content: center;
	width: 50%;
}

.unapprovedPopupHeader {
	@extend .popupHeader;
	justify-content: center;
	width: 121px;
}

.faIcons {
	display: flex;
	justify-content: space-between;
	width: 100px;
	margin-left: 91px;
	padding: 5px;
}

.otherAccountsDiv {
	cursor: pointer;
}

.addAccountContainer {
	@extend .otherAccounts;

	border-bottom-width: 3px;
}

.actionButtons {
	display: flex;
	justify-content: space-between;
	padding: 5px;
	width: 100%;
}

.saveButton,
.cancelButton {
	width: 45%;
}

.modalFooter {
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	box-shadow: var(--box-shadow-reversed);
	border: none;
	background-color: var(--light);

	@media screen and (min-width: 1024px) and (max-width: 1280px) and (max-height: 768px) {
		backdrop-filter: blur(0px);
	}

	@media screen and (min-width: 3440px) and (max-height: 1440px) {
		backdrop-filter: blur(0px);
	}
}

.settingsModalHeader {
	flex-direction: row;
	border: none;
	// box-shadow: var(--box-shadow);
	padding: 12px !important;
	height: 100%;
	border-radius: 20px 0 0 20px;

	.modalTitle {
		display: flex;
		align-items: center;
		font-size: 1.5rem;
		line-height: 1.5rem;

		.settings {
			margin: 18px 0px;
		}

		.closeIcon {
			position: absolute;
			right: 14px;
			top: 8px;
			cursor: pointer;
		}
	}

	.optionsContainer {
		width: 100%;
		font-size: 13px;
		display: flex;
		flex-direction: column;
		text-align: left;

		.profileContainer {
			display: flex;
			height: 48px;
			align-items: center;
			margin-bottom: 8px;
			padding: 0 12px;
			border-radius: 8px;

			&:hover {
				background-color: var(--surface-low);
				border: 1 px border solid var(--border-accent);
				cursor: pointer;
			}

			.profileName {
				font-size: 15px;
				font-weight: 500;
				color: var(--dark);
			}

			.profileEmail {
				color: var(--text-tertiary);
				font-size: 11px;
			}

			.profileImage {
				width: 28px;
				height: 28px;
				flex-grow: 0;
				object-fit: contain;
				border-radius: 31.1px;
				margin-right: 4px;
				display: flex;
				justify-content: center;
				align-items: center;
				&:hover {
					background-color: var(--gray-10);
					cursor: pointer;
				}

				.defaultImage {
					width: 20px;
					height: 20px;
					margin-left: -8px;
				}
			}
			.profileImageInfo {
				height: 36px;
			}
		}

		.profileSettingsBG {
			background-color: var(--surface-low);
			border: 1px solid var(--border-accent);
		}

		.settingsTabContainer {
			color: var(--text-primary);
			height: 40px;
			font-weight: bold;
			cursor: pointer !important;
			padding: 0 12px;
			border-radius: 5px;
			display: flex;
			align-items: center;
			background-color: var(--surface-low);
			border: 1px solid var(--border-accent);
			&:hover {
				background-color: var(--surface-low);
				cursor: pointer;
			}

			.iconContainer {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 24px;
				height: 24px;
				margin-right: 8px;

				&:hover {
					cursor: pointer;
				}
			}

			.settingsTabContainerLabel {
				&:hover {
					cursor: pointer;
				}
			}
		}

		.settingsTabOption {
			cursor: pointer !important;
			color: var(--text-primary);
			transition: all 0.15s ease-in;
			height: 40px;
			padding: 0 12px;
			border-radius: 8px;
			display: flex;
			align-items: center;

			&:hover {
				color: var(--dark);
				background-color: var(--surface-low);
				cursor: pointer;
			}

			.iconContainer {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 24px;
				height: 24px;
				margin-right: 8px;

				&:hover {
					cursor: pointer;
				}
			}

			.settingsTabContainerLabel {
				&:hover {
					cursor: pointer;
				}
			}
		}
	}

	.modalFooter {
		-webkit-backdrop-filter: blur(10px);
		backdrop-filter: blur(10px);
		box-shadow: var(--box-shadow-reversed);
		border: none;
		background-color: var(--light);

		@media screen and (min-width: 1024px) and (max-width: 1280px) and (max-height: 768px) {
			backdrop-filter: blur(0px);
		}

		@media screen and (min-width: 3440px) and (max-height: 1440px) {
			backdrop-filter: blur(0px);
		}

		.actionButtons {
			display: flex;
			justify-content: space-between;
			padding: 5px;
			width: 100%;
		}

		.saveButton,
		.cancelButton {
			width: 45%;
		}
	}
}

.logout {
	display: flex;
	align-items: baseline;
	color: var(--dark);
	padding: 10px;
	margin-left: 5px;
}

.menuSection {
	font-size: 13px;
	overflow-y: auto;
	// only for Firefox
	scrollbar-width: thin;
	padding: 10px 20px 20px 20px;
}

.tradingAccounts {
	@extend .menuSection;
	border-bottom: 1px solid var(--gray-10);
	padding: 20px 20px 10px 20px;
}

.faUsaFlag {
	width: 50px !important;
	height: 50px;
	border-radius: 50%;
	text-align: center;
	padding: 6px;
	border: 3px solid;
}

.faUsaFlagLive {
	@extend .faUsaFlag;
	border-color: var(--mint);
}

.faUsaFlagDemo {
	@extend .faUsaFlag;
	border-color: var(--topaz);
}

// The flag in the popup menu
.popupFlagIcon {
	background: var(--light);
	width: 50px;
	height: 50px;
	border-radius: 50%;
	text-align: center;
	padding: 1px;
	border: 3px solid;
}

.popupFlagIconLive {
	@extend .popupFlagIcon;
	border-color: var(--mint);
}

.popupFlagIconDemo {
	@extend .popupFlagIcon;
	border-color: var(--topaz);
}

.flagLogoAccount {
	cursor: pointer;
	background: var(--light);
	width: 30px !important;
	height: 30px;
	border-radius: 50%;
	text-align: center;
	padding: 1px;
	border: 1px solid var(--topaz);
}

.flagLogoAccountLive {
	@extend .flagLogoAccount;
	border-color: var(--mint);
}

.flagLogoAccountDemo {
	@extend .flagLogoAccount;
	border-color: var(--topaz);
}

.withCurrency {
	margin-top: 3px;
	margin-left: 5px;
	cursor: pointer;
}

.withoutCurrency {
	margin-left: 12px;
}

.dontMatch {
	font-size: 12px;
	color: var(--watermelon);

	margin-left: 25px;
}

.iconCircle {
	background: var(--light);
	width: 35px !important;
	height: 33px;
	border-radius: 50%;
	text-align: center;
	padding: 8px;
	border: 1px solid var(--dark);
	cursor: pointer;
}

.otherAccounts {
	color: var(--dark);
	padding: 10px 0;
	border-bottom: 1px solid var(--gray-10);
	display: flex;
	align-items: center;
	cursor: pointer;

	&:hover {
		background: var(--gray-5);
	}
}

.menuItemsContainer {
	@extend .otherAccounts;
	align-items: center;
	cursor: pointer;

	&:hover {
		background: var(--gray-5);
	}

	&:last-child {
		border: none;
		border-bottom-left-radius: 12px;
		border-bottom-right-radius: 12px;
	}

	.menuItemIconContainer {
		display: flex;
		justify-content: center;
		width: 40px;
		line-height: 0;

		.menuItemIcon {
			font-size: 20px;
		}

		.menuItemIconEnlarged {
			font-size: 24px;
		}
	}

	.menuItemText {
		padding-left: 10px;
	}
}

.logoContainer {
	display: flex;
	align-items: center;
	background: inherit;
	padding: 0 8px;
}

.logo {
	height: 34px;
	width: 183px;
	margin-right: 20px;
}

.accountStats {
	display: flex;
}

.disabledOption {
	opacity: 0.25;
	cursor: not-allowed;
}

.accountContainer {
	border-radius: 100%;
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.liveAddAccountContainer {
	@extend .accountContainer;
	border: 1px solid var(--mint);
}

.demoAddAccountContainer {
	@extend .accountContainer;
	border: 1px solid var(--topaz);
}

.plusIcon {
	font-size: 1.3em;
	color: var(--dark);
}

.instrumentSearchContainer {
	width: 220px;
	height: 36px;
}

.expandedInstrumentSearchContainer {
	width: 760px;
	height: 36px;
}

html[dir='rtl'] {
	.headerUnapprovedView {
		margin-right: 0;
	}
	.questionIcon {
		left: 30px;
		right: initial;
	}

	.passPopup {
		direction: rtl;
	}

	.logoContainer {
		right: 17px;
		left: initial;
	}

	.plusImage {
		margin: 0 0 0 9px;
	}

	.menuItemsContainer {
		.menuItemIconContainer {
			svg.plusIcon {
				margin: 0;
			}
		}

		.menuItemText {
			padding-right: 10px;
			padding-left: 0;
		}
	}

	.popupDivLive {
		margin-left: 0;
		margin-right: 10px;
	}

	.logo {
		margin-right: 0;
		margin-left: 20px;
	}
}

.settingsMain {
	display: flex;
	// max-width: 1600px !important;
	align-items: center;
	justify-content: center;
	z-index: 1500;

	// TODO: this should be removed when all the modals be reworked by design
	& :global {
		.modal-content {
			padding: 0 !important;
		}
	}
}

.settingsWrapper {
	display: flex;
	width: 804px;
	height: 464px;
	padding: 0;
	border-radius: 20px;
	margin-left: -152px;

	background-color: var(--surface-primary);
}

.settingsNavigation {
	width: 240px;
	height: 464px;
	background-color: var(--surface-secondary);
	border-radius: 20px 0 0 20px;
}

.tab {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 564px;
	border-radius: 0 20px 20px 0;
}

:global {
	.modal-content {
		border-radius: 20px;
	}
	.modal-dialog {
		// max-width: 750px !important;
	}
}

html[dir='rtl'] {
	.settingsNavigation {
		border-radius: 0 20px 20px 0;
	}

	.tab {
		border-radius: 20px 0 0 20px;
	}

	.settingsModalHeader {
		border-radius: 0 20px 20px 0;
	}

	:global {
		.modal-content {
			border-radius: 0 20px 20px 0 !important;
		}
		.modal-dialog {
			max-width: 500px !important;
			margin-left: 840px !important;
		}
	}

	.profileImageInfo {
		text-align: right;
		padding-right: 4px;
		overflow: hidden;

		.profileEmail {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
}
