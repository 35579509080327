.searchBox {
	display: flex;
	align-items: center;
	width: 190px;
	background-color: var(--input-low); /* background-color-light */
	border-radius: 4px;
	padding: 0 8px;
	color: var(--text-primary); /* //color-dark */
	caret-color: var(--icon-accent);

	&:hover {
		outline: 1px solid var(--border-primary);
	}

	input {
		border: none;
		background: transparent;
		height: auto;
		padding: 2px 4px;
		width: 100%;

		&:focus,
		&:hover {
			background-color: transparent !important;
			outline: none;
		}
		&::placeholder {
			color: var(--text-tertiary);
		}
	}

	.searchIcon {
		color: var(--icon-tertiary);
	}

	.clearSearchIcon {
		@extend .searchIcon;
		cursor: pointer;
		display: none;
	}

	&.notEmpty {
		.clearSearchIcon {
			display: inline-block;
		}
	}
}

.loadingIcon {
	display: flex;
	justify-content: center;
	align-content: center;
	flex-flow: column nowrap;
	height: 100%;
}

.headingContainer {
	display: flex;
	justify-content: space-between;
	padding: 5px 15px;
}

.iconContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;

	& > * {
		margin-left: 15px;
	}
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 0 15px 0 23px;
	flex-grow: 0;
	height: 36px;
	background-color: var(--surface-primary);

	:global {
		.ui.selection.active.dropdown .menu {
			border: none !important;
			border-bottom-left-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
		}

		.ui.selection.dropdown:active,
		.ui.selection.dropdown:focus {
			border: none !important;
			box-shadow: none !important;
		}
	}

	.tools {
		display: flex;
		align-items: center;

		.closeDropdown {
			background-color: transparent;
			border: none;
			min-width: 80px;
			min-height: unset;
			padding-right: 20px;

			:global {
				.menu{
					min-width: fit-content;
				}
				.menu > .item {
					padding: 0.7rem 0.5rem !important;
					white-space: nowrap;
				}

				i.dropdown.icon {
					padding: 0.91666667em 0.3em;
				}
			}
		}
	}

	.downLoadIcon {
		font-size: 18px;
		margin-right: 12px;
		margin-left: 4px;
		cursor: pointer;
		color: var(--dark);
	}

	.tabs {
		display: flex;
		align-items: flex-end;
		flex-shrink: 0;
		margin-right: auto;
		height: 36px;

		span {
			display: block;
			text-align: center;
		}
	}
}

.content {
	flex-grow: 1;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	border-top: 1px solid var(--border-primary);
	height: calc(100% - 36px);
	width: 100%;
}

.positionTab {
	flex-shrink: 0;
	cursor: pointer;
	margin-right: 28px;
	color: var(--text-primary); /* //color-dark */
	font-size: 13px;
	text-transform: capitalize;
	margin-right: 20px;
	border-bottom: 3px solid transparent;

	&:last-child {
		margin-right: 0;
		margin-left: 0; // reset for Arabic as well
	}

	&.active {
		cursor: pointer;
		border-bottom: 3px solid var(--border-accent); //border-topaz
		color: var(--text-primary); /* //color-dark */
		font-weight: 700;
	}

	&:hover,
	span.reserveSpaceHelper {
		color: var(--text-primary); /* //color-dark */
		font-weight: 700;
	}

	span.tabLabel {
		position: relative;
		padding: 5px 0;
	}

	span.reserveSpaceHelper {
		opacity: 0;
		height: 1px;
		margin: 0;
		overflow: hidden;
	}
}

.loadingImage {
	height: 100px;
}

.loadingText {
	margin: 9px 22px 0;
	color: #8d9396;
}

.ordersTable th {
	padding: 2px 0;
	font-size: var(--fs-12);
	text-align: left;
	position: sticky;
	top: 0;
}

.lightordersTable th {
	background-color: var(--surface-secondary);
	color: var(--text-primary);
	font-weight: 400;
}

.darkordersTable th {
	background-color: var(--color-neutral-4);
	color: var(--color-neutral-3);
	font-weight: 400;
}

.ordersTable td {
	font-size: var(--fs-12);
	text-align: left;
}

.lightordersTable td {
	border-bottom: 1px solid var(--color-neutral-2);
}

.darkordersTable td {
	border-bottom: 1px solid var(--color-neutral-4);
}

.tableOverlayHide {
	display: none;
	padding: 0px;
}

.tableOverlayShow {
	display: table-row;
}

.ordersTable {
	border-collapse: collapse;
	width: 100%;
	table-layout: fixed;
	min-width: 1100px;
}

.orderTableContainer {
	height: 135px;
	overflow-y: auto;
}

.lighttableHeadColor {
	color: var(--color-black);
	white-space: nowrap;
}

.darktableHeadColor {
	color: var(--color-neutral-3);
	white-space: nowrap;
}

.modalDescription {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 80px;
}

.descriptionLine {
	display: flex;
	justify-content: center;
}

.descriptionData {
	display: flex;
	justify-content: space-evenly;
}

.modalButtons {
	display: flex;
	justify-content: space-between;
	float: right;
	width: 150px;
	margin-bottom: 10px;
}

.modalHead {
	color: var(--constant-light) !important;
	background-color: var(--color-neutral-5) !important;
}

.overlay {
	position: absolute;
	z-index: 1;
}

.overlayTop {
	position: absolute;
	z-index: 2;
	top: 50px;
	left: 50px;
}

.lightdropdownTheme {
	color: var(--color-black) !important;
}

.darkdropdownTheme {
	color: var(--constant-light) !important;
}

.lighticonTheme {
	color: var(--color-neutral-3);
	margin-top: 5px;
	margin-left: 15px;
}

.darkiconTheme {
	color: var(--constant-light);
	margin-top: 5px;
	margin-left: 10px;
}

.lightclickTheme {
	color: #f74760;
	margin-top: 5px;
	margin-left: 15px;
}

.lightplusTheme {
	color: black;
	margin-top: 5px;
	margin-left: 15px;
	transition: all 0.15s ease-in;

	&:hover,
	&:active {
		color: white !important;
	}
}

.darkplusTheme {
	color: var(--constant-light);
	margin-top: 5px;
	margin-left: 10px;
}

.paddingLeft {
	padding-left: 10px;
}

.delayedQuote {
	font-size: 11px;
	color: var(--color-neutral-3);
	margin-right: 15px;
}

.darkpositionTitle {
	color: var(--constant-light);
}

.lightpositionTitle {
	color: var(--color-neutral-3);
}

.notificationCustomIcon {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	.fa {
		color: var(--constant-light);
		font-size: 28px;
	}
}

.notificationCustomInfo {
	width: 100%;
	display: flex;
	background-color: var(--surface-primary);
	border-radius: 10px;
	box-shadow: var(--box-shadow-S);

	.notificationCustomIcon {
		border-left: 8px solid var(--color-primary-1);
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
	}
}

.notificationCustom {
	flex-basis: 80%;
	padding: 12px 12px 12px 8px;
	display: inline-block;
}

.notificationMessage {
	color: var(--text-primary); /* //color-dark */
	font-weight: bold;
}

.toastTitle {
	margin-top: 5px;
	color: var(--text-primary); /* //color-dark */
	padding: 5px 0 0 9px;
}

.notificationCustomError {
	width: 100%;
	display: flex;
	background-color: var(--surface-primary);
	border-radius: 10px;
	box-shadow: var(--box-shadow-S);

	.notificationCustomIcon {
		border-left: 8px solid var(--color-red-1);
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
	}
}

.notificationTitle {
	display: flex;
	width: 300px;
	justify-content: space-between;
}

.showIcon {
	margin-top: 10px;
	color: var(--text-primary); /* //color-dark */
}

.lightshowIcon {
	margin-top: 10px;
	color: var(--color-black);
}

.gridLayout {
	background-color: var(--surface-primary);
	height: 100%;
	display: flex;
	position: relative;
	flex-flow: column nowrap;
	:global {
		.dx-treelist-container {
			z-index: 0;
			background-color: var(--surface-primary); /* background-color-gray-5 */
		}

		.dx-treelist-table .dx-data-row {
			background-color: var(--surface-primary); /* background-color-light */
		}

		.dx-treelist-borders > .dx-treelist-rowsview {
			border-bottom: none;
		}
	}
}

.plusButton {
	width: 76px;
	height: 20px;
	background-color: white !important;
	border: 1px solid black !important;
	border-radius: 2px !important;
	transition: background 0.2s ease, color 0.2s ease;

	&:hover,
	&:active {
		border: 1px solid black !important;
		background-color: black !important;
		color: var(--constant-light) !important;
	}
}

.plusContainer {
	margin-left: 30px;
}

.iconContainer {
	margin-top: -12px;
	margin-left: -5px;
}

.tabPane {
	padding: 0px !important;
}

.windowIcon {
	width: 3%;
	position: absolute;
	z-index: 1;
	right: 0;
	margin-top: 7px;
}

.addTicket {
	// height equals to optionsContainer height
	height: 36px;
	z-index: 10;
	padding-right: 20px;

	:global {
		.ui.selection.active.dropdown .menu {
			border-bottom-left-radius: 0px !important;
			border-bottom-right-radius: 0px !important;
		}
	}
}

:global .dx-toolbar-after {
	padding-left: 0px !important;
}

.lightplusTheme {
	font-size: 20px;
	color: black;
	cursor: pointer;

	&:hover,
	&:active {
		color: black !important;
	}
}

.darkplusTheme {
	font-size: 20px;
	color: white;
	cursor: pointer;
}

.gridContainer {
	width: 100%;
	padding-right: 275px;
	justify-content: space-between;
	flex-grow: 0;
}

.closeDropdown {
	border: 0px !important;
	background-color: transparent !important;
	border-radius: 0 !important;
}

html[dir='rtl'] {
	.notificationCustomInfo,
	.notificationCustomIcon,
	.notificationCustomError {
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
	}

	.lighttoastTitle {
		padding: 0 9px 5px 0;
	}

	.gridContainer {
		padding-right: 0px;
	}

	.addTicket {
		padding-left: 275px;
	}

	.positionTab {
		margin-right: 0;
		margin-left: 20px;
	}

	.header {
		height: 36px;

		padding: 0 23px 0 15px;

		.tabs {
			margin-right: 0;
			margin-left: auto;
		}
	}
}
