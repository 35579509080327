.itemWrapper {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  width: max-content;
}

.dropdownIcon {
  font-size: 16px;
  color: var(--light-widget);
  margin-left: 8px;
}

html[dir='rtl'] {
  .dropdownIcon {
    margin-right: 8px;
  }
}
