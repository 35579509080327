.rectangle {
	position: relative;
	z-index: 2;	
	background-color: var(--surface-surface-tertialy); //#E4E8EE var(--surface-surface-tertialy)
	width: 8px;
	height: 32px;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
    align-items: center;
    justify-content: center;
	color: var(--icon-icon-primary);

	&:hover {
		background-color: var(--surface-quaternary);
		cursor: pointer;
	}

	svg {
		transform: rotate(180deg);
		max-height: 9px;
	}
}
