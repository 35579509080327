.createWatchList {
	width: 100%;
	height: 40px;
	margin: auto 0px 0px 0px;
	padding: 10px 10px 10px 10px;
	border-radius: 3px;
	box-shadow: var(--box-shadow-reversed);
	background-color: var(--surface-primary);
	color: var(--text-primary); /* //color-dark */
	text-align: left;
	cursor: pointer;
}

.errorCreateWatchlist {
	@extend .createWatchList;
	padding: 3px 10px 10px 10px;
	cursor: text;
}

.iconContainer {
	display: flex;
	align-items: center;
}

.plusIcon {
	color: var(--text-primary); /* //color-dark */
	margin-right: 10px;
	font-size: 1.3em;
}

.createFooter {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	margin-left: -3px;
}

.createErrorMessage {
	opacity: 0.5;

	font-size: 11px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.27px;
	margin-left: 5px;
}

.errorFooter {
	opacity: 0.5;
	margin-left: 3%;
}

.bottomFooterTitle {
	margin-left: initial;
	margin-right: 4%;
}
