.button {
	align-items: center;
	border-radius: var(--button-br);
	color: var(--color-text-2);
	cursor: pointer;
	display: flex;
	justify-content: center;
	user-select: none;
	font-size: 16px;
	line-height: 1.2;
	white-space: nowrap;
	transition: all linear 0.1s;
	gap: 8px;
	align-items: center;

	svg {
		font-size: var(--fs-20);

		&:hover {
			font-weight: bold;
		}
	}
}

.sm {
	min-height: 36px;
	padding: 8px;
	font-size: var(--fs-13);
}

.md {
	padding: 8px 12px;
	font-size: var(--fs-15);
}

.lg {
	width: 100%;
	min-height: 44px;
	padding: 0px 20px;
	font-size: var(--fs-15);
}

.fluid {
	@extend .lg;

	width: auto;
	max-width: 100%;

	.buttonLabel {
		width: auto;
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.primary {
	background: var(--button-primary);
	color: var(--constant-light);
	border: none;
	font-weight: bold;

	&:hover,
	&:active {
		background: var(--button-primary-hover);
		border-color: var(--button-primary-hover);
	}
}

.danger {
	background: var(--button-negative);
	outline-color: var(--button-negative);
	color: var(--constant-light);
	border: none;
	font-weight: 600;

	&:hover,
	&:active {
		background: var(--button-negative-hover);
		outline-color: var(--button-negative-hover);
	}
}

.outline {
	border: 1px solid var(--border-invert);
	color: var(--text-primary); /* //color-dark */
	background-color: var(--surface-primary); /* background-color-light */
	font-weight: normal;

	&:hover,
	&:active {
		border: 2px solid var(--border-invert);
		font-weight: 600;
	}

	&:active {
		color: var(--light);
		background: var(--dark);
	}
}

// TODO: currently 'secondary' and 'outline' variants have the same style -> TBD with UI/UX team
.secondary {
	// @extend .outline;
	border: 1px solid var(--border-invert);
	color: var(--text-primary);
	background: var(--surface-primary);

	&:hover {
		box-shadow: inset 0px 0px 0px 1px var(--border-invert);
		font-weight: bold;
	}
}

// First workaround the theme, while all the settings are defined
.tertiary {
	@extend .outline;

	outline: 1px solid transparent;
	color: var(--text-primary); /* //color-dark */
	background: var(--gray-10);
}

.disabled {
	background: var(--gray-10);
	outline-color: var(--border-primary); //outline-color--gray-10);
	color: var(--text-secondary);
	border: 1px solid var(--border-primary);

	&:hover,
	&:active {
		background: var(--gray-10);
		outline-color: var(--border-primary); //outline-color--gray-10);
		border: 1px solid var(--border-primary);
		color: var(--text-secondary);
	}
}

.outlineDisabled {
	background: transparent;
	outline-color: transparent;
	border: 1px solid var(--color-neutral-7);
	color: var(--color-text-1);
	transition: none;

	&:hover,
	&:active {
		background: transparent;
		outline-color: transparent;
		border: 1px solid var(--color-neutral-7);
		color: var(--color-text-1);
	}
}

.smallFont {
	font-size: var(--fs-13);
}

.fullWidth {
	// need to discount 1px from left and right because of the outline property been used as border
	width: 100%;
}

.buttonLabel {
	display: block;
	width: 100%;
	overflow-wrap: break-word;
	line-height: normal;
}
