@import 'src/css/common/_custom_scrollbar';
@import 'src/css/common/_input';

.container {
	height: 100%;
	background-color: var(--surface-primary);
}

.containerFluid {
	// watchlist container height - the header
	// WL items holder
	height: calc(100% - 32px);
}

.instrumentBox {
	width: 100%;
	flex-grow: 0;
}

.emptyContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 12px;
	padding: 40px 20px 20px;

	.iconC {
		color: var(--icon-tertiary);
	}
}

.emptyWindowContainer {
	height: 260px;

	@media screen and (max-width: 1023px) {
		padding: var(--p-16) var(--p-24);
	}
}

.editIcon {
	color: var(--text-primary); /* //color-dark */
}

.iconWrapper {
	height: 28px;
	width: 28px;
	display: flex;
	justify-content: center;
	align-items: center;
	&:hover {
		background-color: var(--surface-tertiary);
		border-radius: 50%;
	}
}

.header {
	padding: 0px 8px;
	height: 40px;
	border-bottom: 1px solid var(--border-primary);
	align-items: center;
	display: flex;
	justify-content: space-between;
}

.headerDynamic {
	margin: 0 auto;
}

.windowHeader {
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 10px;

	@media screen and (max-width: 1023px) {
		padding: 0px;
	}
}

.Title {
	font-weight: 700;
	cursor: pointer;
}

.openModalTitle {
	@extend .Title;
	margin-left: auto;
	margin-right: auto;
	// margin-bottom: 5px;
}

.lightTitle {
	color: black;

	font-size: 13px;
	font-weight: bold;
}

.darkTitle {
	color: var(--color-neutral-1);

	font-size: 13px;
	font-weight: bold;
}

.instrumentTitle {
	padding: 0 5px;
	margin-bottom: 0;
}

.emptyTitle {
	font-size: var(--fs-15);
	text-align: center;
	color: var(--text-secondary);
}

.instrumentsContainer {
	width: 100%;
}

.notSticky {
	flex-grow: 0;
	width: 100%;
}

.SearchInputContainer {
	margin-top: var(--m-8);
}

.windowSearchInputContainer {
	width: 300px;
	padding: 10px;

	@media screen and (max-width: 1023px) {
		padding: 0px;
	}
}

.WatchListCategoriesChildNotification {
	height: 80vh;
	overflow-y: scroll;
	padding-right: 17px;
	box-sizing: content-box;
	width: 100%;
}

.MarketTickerContainer {
	@extend %custom-scrollbar-element;

	display: flex;
	flex-flow: column nowrap;
	height: calc(100% - 32px);
	overflow-y: auto;
	overflow-y: overlay;
	overflow-x: hidden;
	cursor: pointer !important;
	background-color: var(--surface-primary); /* background-color-gray-5 */

	// only for Firefox
	scrollbar-width: thin;
}
.noScrollBar {
	scrollbar-width: none;
}

.windowMarketTickerContainer {
	display: flex;
	flex-wrap: wrap;
	max-height: 94%;
	overflow-y: auto;
	overflow-y: overlay;
	overflow-x: hidden;
	width: 100%;
	margin-right: 2%;
}

.loadingWatchlist {
	margin-top: 100px;
}

.windowPadding {
	padding-bottom: 3px;
}

.addInstrument {
	text-align: center;
	padding: 14px 8px;
	display: flex;
	align-items: center;
	padding-left: 15px;
	padding: 14px 8px 14px 15px;
}

.centeredText {
	@extend .addInstrument;
	justify-content: center;
}

.Popup {
	max-width: 210px !important;
	min-width: 250px !important;
	height: auto;
	z-index: 999 !important;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
	background-color: var(--white);
}

.watchlistContainer {
	height: 100px;
}

.PopupContainer {
	display: flex;
	justify-content: space-between;
	width: 100%; //105px
	border-bottom: 1px solid lightgrey;
	font-size: 12px;

	height: 29px;
	margin: 0 3px 0px 0;
	padding: 0px;
	background-color: var(--menu-primary);
}

.editPopupContainer {
	display: flex;
	justify-content: space-between;
	width: 200px; //105px
	font-size: 12px;

	height: 29px;
	margin: 0 3px 0px 0;
	padding-top: 5px;
	background-color: #f3f4f4;
}

.editWindowPopupContainer {
	@extend .editPopupContainer;
	display: flex;
	justify-content: center;
	width: 100%;
}

.watchlistItemsList {
	font-size: 13px;
	font-weight: bold;
	color: var(--text-primary); /* //color-dark */
	bottom: 4px;
	left: 5px;
	position: relative;
	display: flex;
	justify-content: space-between;
	width: 155px;
	margin-top: 5px;
	cursor: pointer;
}

.editWatchlistItemsList {
	text-align: center;

	font-size: 13px;
	font-weight: bold;
	color: var(--text-primary); /* //color-dark */
	bottom: 4px;
	left: 15px;
	position: relative;
}

.watchlistItems {
	padding-left: 10px;
	padding-top: 5px;
	display: flex;
}

.otherItems {
	bottom: 4px;
	left: 5px;
	position: relative;

	font-size: 13px;
	font-weight: normal;
	letter-spacing: -0.31px;
}

.activeWatchList {
	background-color: #f3f4f4 !important;
}

.costInput {
	@extend %cost-input;

	width: 100%;
	max-width: none;
	margin-bottom: 5px;
}

.lightErrorInput {
	@extend .costInput;
	border: 1px solid var(--watermelon);
}

.errorMessage {
	width: 100%;
	font-size: 12px;
	color: var(--watermelon);
}

.btnSave {
	max-height: 37px;
	max-width: 92px;
	padding: 10px 43px 11px;
	width: 195px;
	font-size: 13px;
}

.iconC {
	color: var(--text-primary); //color--gray-10);
	cursor: default;
	font-size: 100px;
}

/* Create a custom checkbox */
.darkCheck {
	position: absolute;
	top: 0;
	left: 0;
	height: 28px;
	width: 28px;
	background-color: white;
	border: 1px solid rgba(39, 56, 64, 0.1);
	border-radius: 15px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .darkCheck {
	background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .darkCheck {
	background-color: white;
}

/* Create the redCheck/indicator (hidden when not checked) */
.darkCheck:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the redCheck when checked */
.container input:checked ~ .darkCheck:after {
	display: block;
}

/* Style the redCheck/indicator */
.container .darkCheck:after {
	left: 7px;
	top: 3px;
	width: 10px;
	height: 15px;
	border: solid var(--color-neutral-2);
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.lightCheck {
	position: absolute;
	top: 0;
	left: 0;
	height: 28px;
	width: 28px;
	background-color: white;
	border: 1px solid rgba(39, 56, 64, 0.1);
	border-radius: 15px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .lightCheck {
	background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .lightCheck {
	background-color: white;
}

/* Create the greenCheck/indicator (hidden when not checked) */
.lightCheck:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the greenCheck when checked */
.container input:checked ~ .lightCheck:after {
	display: block;
}

/* Style the greenCheck/indicator */
.container .lightCheck:after {
	left: 7px;
	top: 3px;
	width: 10px;
	height: 15px;
	border: solid var(--color-neutral-6);
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.selectAllContainer {
	margin-bottom: 0px !important;
}

.disableBtn {
	pointer-events: none;
	color: var(--text-secondary);
	opacity: 0.3;
}

.modifyWatchlist {
	width: 100%;
}

.modifyInputContainer {
	width: 100%;
}

input {
	height: 100%;
}

.modalWatchlist > div {
	margin: auto;
	width: 350px;
	height: 350px;

	// TODO: this should be removed when all the modals be reworked by design
	&:global {
		&.modal-content {
			padding: 0 !important;
			border-radius: 0.3rem !important;
		}
	}
}

.modalRemoveWatchlist > div {
	margin: auto;
	width: 350px;
	height: 234px;
}

.removeMessage {
	font-size: 15px;
	letter-spacing: -0.41px;
	text-align: center;
}

.modalTopHeader {
	border: none;

	@media screen and (min-width: 1024px) and (max-height: 768px) {
		backdrop-filter: blur(0px);
	}

	@media screen and (min-width: 3440px) and (max-height: 1440px) {
		backdrop-filter: blur(0px);
	}
}

.modalTitle {
	width: 100%;
	text-align: center;
}

.modalItems {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.modifyButton {
	width: 100%;
	max-width: none;
	max-height: 40px;
	background-color: var(--surface-primary);
	color: var(--watermelon);
	border: 1px solid var(--watermelon);

	&:hover {
		background: var(--watermelon);
		color: white;
	}
}

.modifyInput {
	@extend .costInput;
	max-width: 100%;
	width: 100%;
	height: 38px;
	padding-left: 10px;
}

.modifyErrorInput {
	@extend .modifyInput;
	border: 1px solid var(--watermelon);
}

.modifyErrorMessage {
	@extend .errorMessage;
	top: 100px;
	left: 20px;
}

.title {
	margin-top: 5px;
	margin-left: 5px;
	margin-bottom: 6px;
}

.modifyFooter {
	padding: 12px;
	border: none;

	@media screen and (min-width: 1024px) and (max-height: 768px) {
		backdrop-filter: blur(0px);
	}

	@media screen and (min-width: 3440px) and (max-height: 1440px) {
		backdrop-filter: blur(0px);
	}
}

.buttonDiv {
	display: flex;
	justify-content: center;
	margin-top: 25px;
}

.inputLimit {
	color: var(--gray-30);
	display: flex;
	justify-content: flex-end;
}

.actionButtons {
	display: flex;
	justify-content: space-between;
	width: 100%;
	gap: 12px;
}

.closeIcon {
	float: right;
	color: var(--text-primary); /* //color-dark */
	cursor: pointer;
	font-size: 22px;
}

.moveModalHeader {
	@extend .modalTopHeader;
	width: 100%;
}

.moveModalTitle {
	width: 100%;
	text-align: center;
}

.modalFooter {
	border-top: none;
	box-shadow: var(--box-shadow-reversed);
}

.moveWatchlist {
	padding: 10px;
	border-bottom: 1px solid var(--gray-10);

	&:last-child {
		border-bottom: none !important;
	}

	& > div > label {
		&::before,
		&::after {
			width: 20px !important;
			height: 20px !important;
		}
	}

	:global .ui.radio.checkbox {
		line-height: revert;
	}
}

:global {
	.ui.checkbox label:hover,
	.ui.checkbox + label:hover,
	.ui.checkbox label,
	.ui.checkbox + label {
		color: var(--text-primary) !important; /* //color-dark */
	}

	.ui.radio.checkbox input:checked ~ label:after {
		background-color: var(--surface-accent) !important;
		border: none !important;
	}

	.ui.checkbox label:before {
		background-color: var(--surface-primary); /* background-color-light */
	}
	.ui.checkbox label:active::before,
	.ui.checkbox input:checked ~ label:before {
		background: var(--surface-primary) !important;
		border: 1px solid var(--border-accent) !important;
	}

	.ui.checkbox label:hover::before {
		background: var(--surface-secondary);
	}
}

.disableButton {
	@extend .saveButton;
	pointer-events: none;
	color: var(--text-secondary);
	background: var(--gray-10);
}

.lightStarIcon {
	font-size: 18px;
}

.darkStarIcon {
	color: white;
}

.addInstrumentContainer {
	display: flex;
}

.detachWatchlistIcon {
	cursor: pointer;
	font-size: 18px;
	color: var(--dark);
}

.icon {
	font-size: 18px;
}

.addToWatchlistIcon {
	cursor: pointer;
	font-size: 18px;
	color: var(--text-primary); /* //color-dark */
}

.headerButtonsWrapper {
	display: flex;
}

.watchlistSearchContainer {
	width: 420px;
	max-height: 300px;
	border-radius: 20px !important;
	padding: 12px !important;

	&,
	&:before {
		background-color: var(--menu-primary) !important;
	}

	.hidden {
		display: none;
	}

	.searchContainer {
		display: flex;
		flex-direction: column;
	}

	.searchTitle {
		font-size: var(--fs-13);
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.36px;
		margin-bottom: 10px;
		color: var(--text-primary); /* //color-dark */
	}

	.searchInputContainer {
		position: relative;
	}

	.searchInput {
		width: 416px;
		height: 34px;
		border-radius: 12px;
		border: solid 1px var(--surface-primary);
		caret-color: var(--icon-accent);
		padding-left: 35px;
		background-color: var(--surface-primary); /* background-color-light */

		&:focus {
			border: solid 1px var(--border-accent);
			outline: none;
		}

		&::placeholder {
			color: var(--text-secondary)
		}
	}

	.openSearchInput {
		@extend .searchInput;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		background-color: var(--surface-primary); /* background-color-light */
		color: var(--text-primary); /* //color-dark */
	}

	.inputIcon {
		position: absolute;
		top: calc(50% - 0.45em);
		color: var(--icon-tertiary);
		left: 10px;
		font-size: 12px;
	}

	.deleteIcon {
		@extend .inputIcon;
		color: var(--color-grey-7);
		font-size: 13px;
		cursor: pointer;
		left: initial;
		right: 10px;
	}
}

.searchResults {
	max-height: 200px;
	overflow-y: auto;
	overflow-y: overlay;
	overflow-x: hidden;
	scrollbar-width: thin; //Firefox scrollbar width

	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		background: none;
	}
}

.hiddenScroll {
	@extend .searchResults;
	overflow: hidden;
}

.boxSearch {
	@extend .searchResults;
	border-bottom-right-radius: 12px;
	border-bottom-left-radius: 12px;
}

.modifyWatchlistModalBody {
	padding: 16px;
}

.selectWatchlistIcon {
	color: var(--text-primary); /* //color-dark */
	font-size: 1.2em;
}

.addInstrumentIcon {
	color: var(--text-primary); /* //color-dark */
	font-size: 1.3em;
	cursor: pointer;
}

html[dir='rtl'] {
	.watchlistItem {
		overflow: visible;
	}
	.watchlistContainer,
	.Popup {
		direction: rtl;
	}

	.inputIcon {
		margin-right: 8px;
		left: initial;
	}

	.searchInput {
		padding: 0px 35px 0px 0px;
	}

	.deleteIcon {
		left: 10px !important;
		right: initial;
	}

	.saveButton {
		margin: 0 10px 0 0;
	}

	.inputContainerText {
		margin-right: auto;
		margin-left: 0;
	}
}
