.container {
	cursor: pointer;
	height: fit-content;
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	padding: 12px;
	background-color: var(--surface-primary); /* background-color-light */
	color: var(--text-primary); /* //color-dark */
	border-radius: 4px;
	gap: 8px;
	&:hover {
		background-color: var(--surface-low); /* background-color-gray-5 */
		.removeIconContainer {
			display: block;
			color: var(--text-primary);
		}
		.buttonHover{
			border-top: 1px solid var(--surface-low);
			border-bottom: 1px solid var(--surface-low);
			background-color: var(--surface-low); /* background-color-light */
		}

		.spreadHover{
			border-bottom: 1px solid var(--surface-low);
			border-left: 1px solid var(--surface-low);
			border-right: 1px solid var(--surface-low);
		}
	}
}
.containerDetached{
	border: 1px solid transparent;
}

.containerLimited {
	max-width: 505px;
}

.iconContainer {
	display: flex;
	flex-direction: row;
	margin-right: 4px;
	gap: 10px;
	align-items: center;
}

.wrapper {
	display: flex;
}

.containerSelected {
	@extend .container;
	.removeIconContainer {
		display: block;
	}
	color: var(--text-primary); //color--gray-10);
  	background-color: var(--surface-low);
	border: 1px solid var(--border-accent);

	&:hover {
		color: var(--surface-primary); //backgro: var(-- gray- 10) !important;
	}
}

.removeIconContainer {
	position: absolute;
	top: 8px;
	right: 15px;
	left: auto;
	width: 13px;
	height: 23px;
	display: none;

	[dir='rtl'] & {
		left: 15px;
		right: auto;
	}

	.removeIcon {
		font-size: 18px;
	}
}

.headerContainer {
	height: 35px;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	overflow: hidden;
	padding: 0;

	.instrumentHeader {
		display: flex;
		align-items: center;
		height: 18px;
		width: 50%;
		flex-grow: 0;

		.instrumentCode {
			display: flex;
			font-size: 13px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: left;
			color: var(--text-primary); /* //color-dark */

			.sessionIcon {
				margin-left: 5px;
				cursor: pointer;
				font-size: var(--font-size-13);
				color: var(--text-secondary);
			}
		}
	}

	.instrumentName {
		display: inline-block;
		height: 18px;
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.54;
		letter-spacing: normal;
		text-align: left;
		color: var(--text-secondary);
	}
}

.orderStatusContainer {
	position: relative;
	top: 8px;
	height: 66px;
	border-top: 1px solid var(--gray-10);
	border-bottom: 1px solid var(--gray-10);
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--surface-primary); /* background-color-light */
}

.loadingContainer {
	@extend .orderStatusContainer;
}

.loadingIcon {
	font-size: 24px;
}

.submittedContainer {
	@extend .orderStatusContainer;
	background-color: var(--surface-positive-secondary);
}

.submittedText {
	font-size: 15px;
	color: var(--text-positive); /* color-mint */
}

.wrongQuantityError {
	height: 20px;
	width: 100%;
	margin-top: 5px;
	text-align: left;
	color: var(--watermelon);
}

// TODO: - Add the modal styling to a separate component
.modalRemoveWatchlist > div {
	margin: auto;
	width: 350px;
	height: 234px;
}

.modalTopHeader {
	border: none;
	backdrop-filter: blur(0px);

	@media screen and (min-width: 1024px) and (max-height: 768px) {
		backdrop-filter: blur(0px);
	}

	@media screen and (min-width: 3440px) and (max-height: 1440px) {
		backdrop-filter: blur(0px);
	}
}

.modalTitle {
	width: 100%;
	text-align: center;
}

.removeMessage {
	font-size: 15px;
	letter-spacing: -0.41px;
	text-align: center;
}

.modifyFooter {
	padding: 12px;
	border: none;

	@media screen and (min-width: 1024px) and (max-height: 768px) {
		backdrop-filter: blur(0px);
	}

	@media screen and (min-width: 3440px) and (max-height: 1440px) {
		backdrop-filter: blur(0px);
	}
}

.actionButtons {
	display: flex;
	justify-content: space-between;
	width: 100%;
	gap: 12px;
}
