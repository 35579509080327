@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300,400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;700&display=swap');
@import 'src/css/_variables';
@import './custom';
@import 'src/css/_fonts';
@import 'src/css/common/_custom_scrollbar';

// CSS vars don't work in media queries so using SASS
$min-width: 1240px;
$min-height: 650px;

* {
	margin: 0;
	padding: 0;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

html {
	background-color: var(--surface-primary); /* background-color-light */
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: var(--surface-primary);
	width: 100%;
	height: 100%;
	overflow: hidden;
	min-width: $min-width;
	min-height: $min-height;

	@media screen and (max-height: $min-height) {
		overflow-y: auto;
	}

	@media screen and (max-width: $min-width) {
		min-width: auto;
		overflow-x: auto;
	}

	@media screen and (max-width: 325px) {
		min-width: 325px;
		overflow-x: auto;
	}

	img,
	svg {
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-o-user-select: none;
		user-select: none;
	}
}

.body__detach {
	min-width: auto;
	min-height: auto;
	//   overflow: auto;

	#root {
		@extend %custom-scrollbar-element;
	}
}

body.isChildWindow {
	min-width: 400px;
	min-height: 284px;
}

#root {
	width: 100%;
	height: 100%;
}

.visible.transition {
	display: grid !important;
	visibility: visible !important;
}

/* React-DatePicker Overriden CSS */
.react-datepicker {
	box-shadow: var(--box-shadow-S) !important;
	border-radius: 13px !important;
	border: 1px solid var(--border-secondary) !important;
}

.react-datepicker__portal {
	justify-content: left !important;
	padding-left: 400px !important;
	width: 100% !important;
	height: 100% !important;
}

.react-datepicker__input-time-container {
	display: flex !important;
	flex-direction: column !important;
}

.react-datepicker__input-time-container .react-datepicker-time__caption,
.react-datepicker__input-time-container .react-datepicker-time__caption::before {
	display: inline-block;
	padding-bottom: 3px !important;
	color: var(--color-neutral-3) !important;
}

.react-datepicker__header {
	background-color: #34474f !important;
	border: solid 1px rgba(147, 155, 159, 0.2) !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
	margin-top: 6px !important;
	color: var(--constant-light) !important;

	font-size: 12px !important;
	font-weight: 500 !important;
	font-stretch: normal !important;
	font-style: normal !important;
	line-height: 1.43 !important;
	letter-spacing: normal !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
	color: #939b9f !important;
	font-size: 12px !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
	border-radius: 2rem !important;
	background-color: var(--gray-5) !important;
	color: #121b1e !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
	border-radius: 2rem !important;
	background-color: transparent !important;
	color: #121b1e !important;

	&:hover {
		background: var(--gray-10) !important;
	}
}

.react-datepicker__month {
	border-bottom: solid 1px rgba(255, 255, 255, 0.2) !important;
}

.react-datepicker__input-container,
.react-datepicker__input-container > input {
	font-size: 12px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
	display: inline-block;
	margin-left: 0px !important;
	border: solid 1px rgba(255, 255, 255, 0.2) !important;
	background-color: #34474f !important;
	width: 136px !important;
	height: 32px !important;
	border-radius: 2px !important;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
	// outline-color: red !important;
}

.react-datepicker__portal .react-datepicker__navigation--next:active {
	outline-color: #34474f !important;
}

/* ChartIQ Periodicitiy Dropdown */
.ui.dimmer {
	background-color: rgba(0, 0, 0, 0.5);
	opacity: 0.5;
}

.dx-treelist-header-panel {
	padding: 0;
	background-color: rgba(85, 149, 222, 0.6);
}

.dx-treelist-header-panel .dx-toolbar {
	margin: 0;
	padding-right: 50px;
	background-color: transparent;
	margin-bottom: 0px !important;
	position: absolute;
	z-index: 15;
	top: -40px;
	right: 0px;
	width: 300px;
}

.dx-treelist-headers .dx-treelist-table .dx-row > td {
	border-right: 1px solid var(--gray-10) !important;

	font-size: 13px;
	text-align: left !important;
}

.dx-treelist-rowsview .dx-row.dx-group-row {
	background-color: white !important;
	color: black !important;
	font-weight: normal !important;
}

.dx-treelist-content .dx-treelist-table .dx-row > td.dx-treelist-group-space {
	border-right: none !important;
}

.dx-treelist-rowsview .dx-row {
	font-size: 13px;
	font-weight: normal;
}

.dx-treelist-headers
	.dx-treelist-table
	.dx-row
	> td:hover:not(.dx-command-select):not(.dx-command-expand):not(.dx-editor-cell):not(.dx-command-edit):not(
		.dx-treelist-group-space
	) {
	background-color: #f8f8f8 !important;
	color: black;
	font-weight: bold;
}

.dx-treelist .dx-column-indicators {
	float: right;
}

.dx-treelist .dx-sort-up::before {
	content: '\f014' !important;
}

.dx-treelist .dx-sort-down::before {
	content: '\f016' !important;
}

.dx-treelist-content .dx-treelist-table.dx-treelist-table-fixed .dx-column-indicators .dx-sort.dx-sort-index {
	display: none;
}

.dx-header-row .dx-sort-indicator.dx-text-content-alignment-right {
	margin-left: 0px !important;
}

.dx-treelist-header-panel .dx-toolbar {
	width: 150px;
}

.dx-treelist-rowsview.dx-empty {
	height: 160px !important;
}

.dx-treelist .dx-treelist-rowsview .dx-row-focused.dx-data-row:not(.dx-edit-row) > td:not(.dx-focused) {
	background-color: #f3f4f4 !important;
	color: black;
	border-bottom: none !important;
	border-top: none !important;
}

/*Datagrid*/
.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
	&:nth-child(1),
	&:nth-child(2) {
		border-right: 1px solid var(--gray-10) !important;

		font-size: 13px;
		text-align: left !important;
	}
}

.dx-datagrid .dx-column-lines > td {
	border-left: none !important;
	border-right: none !important;
}

.dx-datagrid .dx-column-indicators {
	float: right !important;
}

.dx-datagrid .dx-sort-up::before {
	content: '\f014' !important;
}

.dx-datagrid .dx-sort-down::before {
	content: '\f016' !important;
}

.dx-datagrid-column-chooser,
.dx-treelist-column-chooser {
	&.dx-popup-wrapper {
		.dx-popup-draggable .dx-popup-title {
			cursor: text;
		}

		.dx-resizable-handle {
			pointer-events: none;
			cursor: text !important;
		}
	}
}

.my-tooltip > .tooltip-inner {
	background-color: white !important;
	color: #000;
	box-shadow: var(--box-shadow-S);
	font-size: 14px;
	z-index: 999 !important;
}

.my-tooltip.show {
	opacity: 1 !important;
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='bottom'] .tooltip-arrow::before {
	border-bottom-color: var(--light) !important;
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='top'] .tooltip-arrow::before {
	border-top-color: var(--light) !important;
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='right'] .tooltip-arrow::before {
	border-right-color: var(--light) !important;
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='left'] .tooltip-arrow::before {
	border-left-color: var(--light) !important;
}

.ui.selection.dropdown .menu > .item {
	border-top: none;
}

.modal-content {
	background: var(--surface-primary) !important;
	color: var(--text-primary) !important; /* //color-dark */
	border: none !important;
	border-radius: var(--modal-dialog-content-br);
	// padding: 16px;
	box-shadow: var(--box-shadow-S);
}

input,
.ui.input > input {
	background-color: var(--surface-primary); /* background-color-light */
	color: var(--text-primary); /* //color-dark */

	&:focus,
	&:active {
		background-color: var(--input-low);
		border: 1px solid var(--border-accent); /* background-color-light */
		color: var(--text-primary); /* //color-dark */
	}
}

// ================ Dropdown ================
// dropdown header
.ui.selection.visible.dropdown > .text:not(.default) {
	color: var(--text-primary) !important; /* //color-dark */
}

// dropdown header - when the dropdown is open
.ui.selection.dropdown {
	color: var(--text-primary) !important; /* //color-dark */

	&:active,
	&:focus {
		color: var(--text-primary) !important; /* //color-dark */
	}
}

.ui.selection.active.dropdown .menu {
	border: none;
}

.ui.selection.active.dropdown:hover,
.ui.selection.active.dropdown .selected,
.ui.selection.dropdown:focus {
	border-color: var(--border-secondary);
}

// dropdown body
.ui.selection.active.dropdown .menu {
	background: var(--menu-primary) !important;
	color: var(--text-primary) !important; /* //color-dark */
	@extend %custom-scrollbar-element;
	scrollbar-gutter: auto;
}

// dropdown item
.ui.selection.dropdown .menu > .item {
	background: var(--menu-primary) !important;
	color: var(--text-primary) !important; /* //color-dark */
	font-size: 13px !important;
}

// dropdown item selected
.ui.dropdown .menu .selected.item,
.ui.dropdown.selected {
	background: var(--surface-low) !important;
	border: 1px solid var(--border-accent);
	color: var(--text-primary) !important; /* //color-dark */
}

// dropdown item hover
.ui.dropdown .menu > .item:hover {
	background: var(--surface-low) !important;
}

.ui.toggle.checkbox {
	& .box:before,
	& label:before,
	& input:focus ~ label:before {
		color: var(--surface-primary); //backgro: var(-- gray- 10) !important;
		border-color: transparent !important;
	}
}

.ui.toggle.checkbox.checked {
	& .box:before,
	& label:before,
	& input:checked ~ label:before,
	& input:focus ~ label:before {
		background-color: var(--surface-accent) !important;
	}
}

// ===========================================

// ================ Accordion ================
// The content of the accordion
.accordion-item {
	background: var(--light) !important;
	color: var(--text-primary) !important; /* //color-dark */
	border-top: 0px !important;
	border-right: 0px !important;
	border-left: 0px !important;
	border-bottom: solid 1px var(--gray-30) !important;
	border-radius: 0px !important;
}

.accordion-button {
	background: var(--light) !important;
	color: var(--text-primary) !important; /* //color-dark */
	justify-content: space-between;
	padding: 12px 10px !important;
}

.accordion-button::after {
	margin-left: initial;
}

.accordion-item:first-of-type .accordion-button {
	border-radius: 0px !important;
	box-shadow: none;
}

.accordion-button::after {
	background: var(--dark);
	mask-image: url('/dropdownChevron.svg') !important;
	background-image: none !important;
	width: 14px !important;
	height: 14px !important;
}

// ===========================================

// ================ RTL ======================

html[dir='rtl'] {
	.dx-treelist-header-panel .dx-toolbar {
		right: 0;
		left: 150;
	}

	.dx-treelist-headers .dx-treelist-table .dx-row > td {
		text-align: right !important;
	}

	.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
		text-align: right !important;
		padding-right: 8px;

		&:first-child {
			border-right: none !important;
		}
	}

	.dx-datagrid .dx-datagrid-rowsview .dx-column-lines > td {
		text-align: right !important;
		padding: 11px 4px 11px 17px;
	}

	.ui.dropdown .menu > .item {
		text-align: right;
	}

	.ui.dropdown .menu > .item > img {
		margin-right: -4px !important;
		margin-left: 8px;
	}

	.SearchInput_SearchButton__2oIa2 {
		margin-right: 93%;
	}

	.dx-scrollbar-vertical {
		left: 0 !important;
		right: initial !important;
	}

	.timeDisplay {
		text-align: left;
		padding: 0 29px;
	}

	.loaderGif {
		left: 200px;
		right: 0px;
	}

	.dx-treelist-column-chooser {
		top: -14px !important;
	}

	.dx-column-indicators {
		float: left !important;
	}

	.ui.selection.dropdown {
		padding: 0.785714em 1.1em 0.78571429em 2.1em;
	}

	.ui.dropdown > .text > img {
		margin: 0 0.58571429rem;
	}

	i.dropdown.icon.icon.icon {
		left: 12px;
		right: initial;
		padding-right: 12px;
	}

	#layerManagementDropdown {
		left: -74px;
	}
}

// In rare cases where two or more dialogs are open,
// make sure at least the extra backdrop layers are not displayed
body > .modal-backdrop.show ~ .modal-backdrop.show {
	display: none;
}

.modal-title {
	padding: 0 16px;
}

// Notification Dialog Global Animation
@keyframes showIt {
	0% {
		transform: translateY(0px);
	}
	100% {
		transform: translateY(-155px);
	}
}

@keyframes hideIt {
	0% {
		transform: translateY(-155px);
	}
	100% {
		transform: translateY(0px);
	}
}

#sw-update-notification {
	animation-duration: 0.5s;
	-webkit-animation-fill-mode: forwards; /* Chrome 16+, Safari 4+ */
	-moz-animation-fill-mode: forwards; /* FF 5+ */
	-o-animation-fill-mode: forwards; /* Not implemented yet */
	-ms-animation-fill-mode: forwards; /* IE 10+ */
	animation-fill-mode: forwards; /* When the spec is finished */
}

#sw-update-notification.isDialogVisible {
	animation-name: showIt;
	z-index: 2000;
}

#sw-update-notification:not(.isDialogVisible) {
	animation-name: hideIt;
	z-index: -1;
}
