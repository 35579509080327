.titleContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 20px;
	padding: 20px;
	border-bottom: 1px solid var(--grey-5);
	background-color: var(--surface-primary);
}

.titleWatchlist {
	font-size: 13px;
	font-weight: bold;
}

.watchlistContainer {
	margin-right: 3px;
	max-height: 151px;
	overflow-y: auto;
	height: 100%;
	overflow-x: hidden;
	white-space: nowrap;
	scroll-behavior: smooth;
}

.watchlistContainer::-webkit-scrollbar-track-piece {
	background-color: white;
}

.watchlistContainer::-webkit-scrollbar {
	width: 4px;
}

i {
	cursor: pointer;
}

.watchlistItem {
	width: 10rem;
	height: 100%;
	margin-left: 10px;
	align-items: center;
	overflow-x: hidden;
	text-overflow: ellipsis;
}

.itemContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid var(--gray-10);
	background-color: var(--surface-primary);
	white-space: nowrap;
	padding: 5px 10px;
	cursor: pointer;
	user-select: none;

	&:hover {
		color: var(--text-primary); //color--gray-10);
	}
}

.addInstrumentContainer {
	display: flex;
}

.addToWatchlistIcon,
.existsInWatchlistIcon,
.watchlistIcon {
	font-size: 1.3em;
	cursor: pointer;
}

.existsInWatchlistIcon {
	color: var(--icon-accent); /* color-mint */
}

.maxItemContainer {
	@extend .itemContainer;
	opacity: 0.3;
	padding: 3px 0px;
}

.maxErrorMessage {
	font-size: 11px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.27px;
}
