@import 'src/css/common/_vertically_center';

.header {
	font-size: 16px;
	background-color: var(--surface-primary);
	color: var(--text-primary); /* //color-dark */
	z-index: 1;
	width: 100%;
	padding: 20px;
	box-shadow: var(--box-shadow);
	backdrop-filter: blur(10px);
	div {
		position: relative;

		.iconContainer {
			position: absolute;
			top: -4px;
			right: -5px;

			&:hover {
				cursor: pointer;
			}

			svg {
				max-width: 14px;
				max-height: 14px;
			}
		}
	}
}

.bal {
	@extend .header;
}

.resizeHeaderText {
	@extend .header;
	font-size: 15px;
	position: relative;
}

.closeContainer {
	border-top: 1px solid var(--gray-10);
	background-color: var(--surface-primary); /* background-color-gray-5 */
}

.lightcloseContainer {
	padding: 10px 30px;
	border-top: 1px solid var(--color-grey-5);
	background-color: rgba(28, 40, 46, 0.05);
	overflow-y: auto;
	height: 100%;
}

.closeContainerModal {
	padding-bottom: 17px;

	.closeFields {
		padding-right: 0;
		padding-left: 0;
	}
}

.closeFields {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 12px;
	border-bottom: 1px solid var(--gray-10);
	color: var(--text-primary); /* //color-dark */
}

.orderModalFooter {
	background-color: var(--surface-primary);

	@media screen and (min-height: 600px) and (max-height: 799px) {
		backdrop-filter: blur(0px);
	}
	@media screen and (min-height: 800px) {
		backdrop-filter: blur(0px);
	}
}

.modalPartialButtons {
	display: flex;
	justify-content: space-between;
	padding: 9px 12px;
	box-shadow: var(--box-shadow-reversed);

	& > * {
		flex-grow: 1;
		max-width: unset;

		&:first-child {
			margin-right: 4px;
		}
		&:last-child {
			margin-left: 4px;
		}
	}
}

.lightticketButton {
	background: var(--constant-light);
	border: 1px solid var(--color-black);
	max-height: 37px;
	max-width: 128px;
	padding: 10px 43px 11px;
	transition: all 0.15s ease-in;
	width: 195px;
	color: black;

	&:hover,
	&:active {
		border: 1px solid var(--color-neutral-5);
		background: var(--color-neutral-5);
		color: var(--constant-light);
	}
}

.darkticketButton {
	background: var(--color-neutral-5);
	border: 1px solid var(--constant-light);
	color: var(--constant-light);
	max-height: 37px;
	max-width: 128px;
	padding: 10px 43px 11px;
	transition: all 0.15s ease-in;
	width: 195px;

	&:hover,
	&:active {
		border: 1px solid var(--constant-light);
		background: var(--constant-light);
		color: var(--color-black);
		outline-color: var(--color-primary-1);
		outline-style: none !important;
		outline-width: 0px !important;
	}
}

.lightticketButtonClose {
	@extend .lightticketButton;
	background: var(--surface-accent);
	border: 1px solid var(--border-accent);
	color: white;
}

.darkticketButtonClose {
	@extend .darkticketButton;
	background: var(--surface-accent);
	border: 1px solid var(--border-accent);
}

.ticketInfo {
	background-color: var(--surface-primary); /* background-color-light */
	flex-grow: 1;
	overflow-y: auto;
}

.closeButtonLocation {
	@include vertically-center;
}

html[dir='rtl'] {
	.date {
		direction: ltr;
	}

	.modalPartialButtons {
		& > * {
			&:first-child {
				margin-left: 4px;
			}
			&:last-child {
				margin-right: 4px;
			}
		}
	}
}
