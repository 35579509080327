//            TradingSettings styles
.platformInactivity {
	@extend .platform;
	width: 95%;
	display: flex;
	flex-direction: column;
}

.platform {
	font-size: 16px;
	color: var(--text-primary); /* //color-dark */
	padding: 22px 0px;
	display: flex;
	justify-content: space-between;
	width: 95%;
	border-bottom: 1px solid var(--gray-10);

	.checkContainer {
		display: flex;

		.container {
			display: block;
			position: relative;
			padding-left: 35px;
			margin-bottom: 12px;
			cursor: pointer;
			font-size: 22px;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			margin-top: -3px;
		}

		/* On mouse-over, add a grey background color */
		.container:hover input ~ .redCheck {
			background-color: var(--surface-primary); /* background-color-gray-5 */
		}

		/* When the checkbox is checked, add a blue background */
		.container input:checked ~ .redCheck {
			background-color: var(--surface-accent);
		}

		/* Show the redCheck when checked */
		.container input:checked ~ .redCheck:after {
			display: block;
		}

		/* Style the redCheck/indicator */
		.container .redCheck:after {
			left: 7px;
			top: 3px;
			width: 10px;
			height: 15px;
			border: solid var(--surface-low);
			border-width: 0 3px 3px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}

		/* Hide the browser's default checkbox */
		.container input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;
		}

		.redCheck {
			position: absolute;
			top: 0;
			left: 0;
			height: 28px;
			width: 28px;
			background-color: var(--surface-primary);
			border: 1px solid var(--border-accent); //border-topaz
			// border: 1px solid var(--topaz);
			border-radius: 15px;
			cursor: pointer;
		}
		.redCheck:after {
			content: '';
			position: absolute;
			display: none;
		}

		.toggleButton {
			display: flex;
			align-items: center;
			label {
				cursor: pointer !important;

				&::after {
					width: 20px;
					height: 20px;
					top: -3px;
				}
				&::before {
					width: 34px;
					height: 14px;
				}
			}
		}
		.toggleButtonChecked {
			display: flex;
			align-items: center;
			label {
				&::before {
					width: 34px;
					height: 14px;
				}
				&::after {
					width: 20px;
					height: 20px;
					top: -3px;
					left: 17px !important;
				}
			}
		}
	}

	.titleContainer {
		display: flex;
		flex-direction: column;
		gap: 5px;
	}

	.rangeContainer {
		display: flex;
		align-items: center;
		width: 37%;
		justify-content: space-between;
		input {
			width: 80%;
			height: 80%;
		}

		.slider {
			width: 100%;
			padding-left: 12px;
		}

		.iconContainer {
			min-width: 28px;
			min-height: 28px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 4px;
			&:hover {
				cursor: pointer;
				background-color: var(--surface-primary); /* background-color-gray-5 */
			}
		}
	}
}

//            SecuritySettings styles

.platformInactivity {
	@extend .platform;
	width: 95%;
	display: flex;
	flex-direction: column;

	.platformContainer {
		display: flex;
		width: 100%;
		flex-direction: row;
		align-items: baseline;

		div {
			min-width: 50%;
			display: flex;
			align-items: baseline;
			justify-content: space-between;
		}
		.costInput {
			display: flex;
			width: 100%;
			max-width: 220px;
			background-color: var(--surface-primary) !important;
			box-shadow: var(--input-field-box-shadow);
			border: solid 1px var(--border-primary);
			height: 38px;
			border-radius: 10px;

			input {
				color: var(--text-primary); /* //color-dark */
				background-color: var(--surface-primary) !important;
				max-width: 190px;
				display: flex;
				flex-shrink: 2;
			}
		}

		.questionIcon {
			margin-left: 12px;
			padding: 0;
			cursor: pointer;
			align-self: center;
		}
	}

	.errorMessage {
		font-size: 12px;
		color: var(--watermelon);
		width: 100%;
		margin: -12px 0px 12px 12px;
	}
	.errorMessagePhone {
		font-size: 12px;
		color: var(--watermelon);
		width: 100%;
		text-align: end;
	}
}
.passPopup {
	top: 0% !important;
	z-index: 999999 !important;
	min-width: 12% !important;
	min-height: 8% !important;
	max-height: 38% !important;

	// The scss for the bootstrap popup container content
	.passwordPopup {
		padding: 8px;

		.headerMessage {
			color: var(--text-primary); /* //color-dark */
			font-size: 14px;

			margin-bottom: 5%;
		}
		.check {
			margin-bottom: 7%;
		}

		label {
			cursor: default !important;
		}

		.check > div > label::before {
			width: 20px !important;
			height: 20px !important;
			border-radius: 10px !important;
			cursor: default;
		}

		.check > div > label::after {
			width: 20px !important;
			height: 20px !important;
			background-color: var(--color-green-3) !important;
			color: var(--surface-low) !important;
			border-radius: 10px;
			cursor: default;
		}
	}
}

//            PlatformSettings styles

.dropdownMenu {
	height: 30px !important;
	padding: 0 !important;
	min-width: 0px !important;
	min-height: 0px !important;
	border-radius: 3px !important;
	font-size: 13px !important;
	background-color: var(--surface-low) !important;
	color: var(--dark) !important;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: burlywood;
	.diveder {
		padding-left: 12px;
	}
}

.dropdownMenuLang {
	width: 100%;
	padding: 12px;
	display: flex;
	justify-content: space-between;
	padding: 12px;
	align-items: center;
	background-color: burlywood;
}

.platformTab {
	@extend .platformInactivity;
	width: 100%;
	height: 100%;
	padding: 24px 36px;
	border-radius: 0 20px 20px 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	border: none;
	position: relative;
	input {
		border: none;
		background-color: var(--surface-primary);
		border-radius: 4px;
		padding: 6px 12px;
		// max-width: 180px;
	}

	.closeIcon {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		right: 12px;
		top: 12px;
		cursor: pointer;
		width: 36px;
		height: 36px;
		&:hover {
			background-color: var(--surface-primary);
			border-radius: 8px;
		}

		.icon {
			font-size: 20px;
			font-weight: lighter;
		}
	}

	.inactivity {
		margin-top: -10px;

		.dropdownMenu {
			background-color: var(--surface-primary) !important;
			color: var(--text-primary) !important; /* //color-dark */
		}
	}

	.profileSetingsHeader {
		display: flex;
		justify-content: space-between;
		margin-bottom: 8px;
		border-radius: 12px;

		.platformTabName {
			font-size: 20px;
			font-weight: bold;
		}
	}
	.profileSetingsContent {
		color: var(--text-primary);
		border-radius: 12px;

		.additionalPadding {
			padding: 12px 0;
		}
		.settingsPairs {
			display: flex;
			justify-content: space-between;
			height: 64px;
			align-items: center;
			border-bottom: solid 1px var(--border-primary);
			padding: 12px 0;

			// &:last-of-type {
			// 	border: none;
			// }

			.settingsPairsName {
				font-size: 13px;
				font-weight: normal;
			}

			.settingsQuantityButtons {
				display: flex;
				gap: 8px;
				&:hover {
					cursor: pointer;
				}
				.quantityButton {
					display: flex;
					align-items: center;
					// gap: 8px;
					border: 1px solid var(--border-primary);
					width: 120px;
					height: 40px;
					border-radius: 12px;
					.outerCircle {
						margin: 0 8px;
						width: 20px;
						height: 20px;
						border-radius: 50%;
						border: 5px solid var(--border-primary); /* Blue outline */
						display: flex;
						justify-content: center;
						align-items: center;
					}

					.innerCircle {
						width: 10px;
						height: 10px;
						background-color: var(--surface-primary); /* White inner circle */
						border-radius: 50%;
					}
				}

				.quantityButtonSelected {
					// border: 1px solid var(--text-accent);
					border-radius: 12px;
					width: 120px;
					height: 40px;
					.outerCircle {
						margin: 0 8px;

						width: 20px;
						height: 20px;
						border-radius: 50%;
						border: 5px solid var(--text-accent); /* Blue outline */
						display: flex;
						justify-content: center;
						align-items: center;
					}

					.innerCircle {
						width: 10px;
						height: 10px;
						background-color: var(--surface-low); /* White inner circle */
						border-radius: 50%;
					}
				}
			}

			.dropdownDocs {
				display: flex;
				justify-content: end;
				gap: 4px;
			}
			.newPasswordInput {
				width: 100%;
				height: 44px;
				&:last-of-type {
					margin-top: -20px;
				}
			}

			.invalidPassword {
				border: 1px solid var(--text-alert);
			}

			.eyeIcon {
				position: absolute;
				right: 12px;
				top: 16%;
				cursor: pointer;
				width: 24px;
				height: 24px;
				user-select: none;
				color: var(--icon-secondary);
			}

			.settingsPairsPhone {
				display: flex;

				.rangeContainer {
					display: flex;
					align-items: center;
					width: 160px;
					justify-content: space-between;
					input {
						width: 80%;
						height: 80%;
					}

					.slider {
						width: 100%;
						padding-left: 12px;
					}

					.iconContainer {
						min-width: 28px;
						min-height: 28px;
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 4px;
						&:hover {
							cursor: pointer;
							background-color: var(--surface-primary);
						}
					}
				}
				.settingsPairsPhoneCodeWrapper {
					display: flex;
					justify-content: end;
					width: 50%;
				}
				.settingsPairsPhoneNumber {
					margin-left: 8px;
				}
				input {
					border: none;
					background-color: var(--surface-primary);
					border-radius: 4px;
					padding: 12px;
					max-width: 180px;
				}

				.mobileCountryCode {
					max-width: 80px;
				}
			}

			.settingsPairsImgDropdown {
				display: flex;
				justify-content: space-between;
				min-width: 60px;
				align-items: center;
				position: relative;
				width: 180px;

				.dropdownMenuLang {
					padding: 12px !important;
				}

				.menu {
					border: none;
					width: max-content;
				}

				&:hover {
					cursor: pointer;
				}

				.settingsPairsImgDropdownArrow {
					width: 0;
					height: 0;
					border-left: 4px solid transparent;
					border-right: 4px solid transparent;

					border-top: 4px solid var(--border-primary);
				}

				.imgContainer {
					display: flex;
					justify-content: start;
					align-items: center;
					flex-wrap: wrap;
					width: 368px;
					height: 272px;
					gap: 8px;
					background-color: var(--surface-secondary);
					position: absolute;
					right: 0;
					top: 44px;
					padding: 20px;
					border-radius: 8px;
					z-index: 1;

					img {
						width: 40px;
						height: 40px;
						&:hover {
							cursor: pointer;
						}
					}
				}
			}

			.dropdownMenuLangWrapper {
				background-color: var(--input-primary);
				border-radius: 8px;
				height: 44px;

				.dropdownMenuLang {
					background-color: var(--input-primary) !important;
					display: flex;
					min-height: 44px !important;
					border-radius: 8px !important;

					.menu > .item {
						background-color: none !important;
						width: max-content;
					}
				}

				.dropdownMenuLang:hover {
					background-color: var(--input-secondary) !important;
					min-height: 44px !important;
					border-radius: 8px !important;
				}

				.dropdownMenuSecurity {
					font-size: 14px !important;
				}
			}

			.justifyContentFlexEnd {
				justify-content: flex-end;
			}
			.dropdownIconWrapper {
				height: 40px;
				width: 40px;
				background-color: var(--surface-primary);
				border-radius: 150px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 12px;

				.dropdownDefaultImage {
					height: 24px;
					width: 24px;
				}
			}

			.dropdownIconWrapper:hover {
				background-color: var(--input-secondary);
			}
		}

		.passwordInputWrapper {
			padding: 0;
			margin: 0;
			position: relative;
		}

		.phoneNumberAndCode {
			display: flex;
			justify-content: end;
		}

		.settingsUpload {
			width: 100%;
			height: 140px;
			gap: 8px;
			padding: 0;
			border-radius: 12px;
			border: dashed 1px var(--border-primary);
			stroke-dasharray: '20';
			stroke-dashoffset: '68';
			display: flex;
			justify-content: center;
			align-items: center;

			.fileInput {
				display: none;
			}

			.fileUploadLabel {
				display: flex;
				align-items: center;
				padding: 10px 20px;
				// background-color: var(--gray-5);
				border-radius: 4px;
				cursor: pointer;
				font-size: 16px;
				text-align: center;
			}

			.fileUploadLabel:hover {
				background-color: var(--surface-primary);
			}
		}
	}

	.settingsPairsTheme {
		.settingsPairsName {
			font-size: 13px;
			font-weight: normal;
		}
		.settingsThemeWrapper {
			display: flex;
			max-width: 100%;
			justify-content: space-between;
			border-radius: 10px;
			cursor: pointer;

			.inactive {
				border: 1px solid var(--border-secondary);
			}
			.singlethemeWrapper {
				display: flex;
				flex-direction: column;
				width: 154px;
				min-height: 96px;
				border-radius: 12px;

				.themeRadioWrapper {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 4px;
					padding: 0 12px;
				}
				.themeImage {
					border-radius: 10px 10px 0 0;
					width: 100%;
					height: 100%;
					background-size: cover;
					opacity: 0.8;
				}
				.themeImageSystem {
					background-image: url('../../../../../../images/theme/ThemeSystem.svg');
				}
				.themeImageDark {
					background-image: url('../../../../../../images/theme/ThemeDark.svg');
				}
				.themeImageLight {
					background-image: url('../../../../../../images/theme/ThemeLight.svg');
				}
			}

			.active {
				border: 3px solid var(--border-accent);
				.themeImage {
					opacity: 1;
				}
				input {
					accent-color: var(--text-accent);
				}
			}
		}
	}

	.borderBottom {
		border-bottom: 1px solid var(--border-primary);
		padding-bottom: 12px;
	}

	:global {
		.ui.selection.dropdown {
			border: none !important;
			box-shadow: none !important;
			padding-right: 0;
			// min-width: auto;
		}
	}

	.checkContainer {
		display: flex;

		.container {
			display: block;
			position: relative;
			padding-left: 35px;
			margin-bottom: 12px;
			cursor: pointer;
			font-size: 22px;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			margin-top: -3px;
		}

		/* On mouse-over, add a grey background color */
		.container:hover input ~ .redCheck {
			background-color: var(--surface-primary); /* background-color-gray-5 */
		}

		/* When the checkbox is checked, add a blue background */
		.container input:checked ~ .redCheck {
			background-color: var(--surface-accent);
		}

		/* Show the redCheck when checked */
		.container input:checked ~ .redCheck:after {
			display: block;
		}

		/* Style the redCheck/indicator */
		.container .redCheck:after {
			left: 7px;
			top: 3px;
			width: 10px;
			height: 15px;
			border: solid var(--surface-low);
			border-width: 0 3px 3px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}

		/* Hide the browser's default checkbox */
		.container input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;
		}

		.redCheck {
			position: absolute;
			top: 0;
			left: 0;
			height: 28px;
			width: 28px;
			background-color: var(--surface-primary);
			border: 1px solid var(--border-accent); //border-topaz
			border-radius: 15px;
			cursor: pointer;
		}
		.redCheck:after {
			content: '';
			position: absolute;
			display: none;
		}

		.themeLabel {
			margin-right: 15px;
		}
	}
}

input:-webkit-autofill {
	background-color: var(--surface-secondary) !important;
	box-shadow: 0 0 0px 1000px var(--gray-5) inset !important;
}

/* Autofill styles for Firefox */
input:-moz-autofill {
	background-color: var(--surface-secondary) !important;
	box-shadow: 0 0 0px 1000px var(--gray-5) inset !important;
}

.platformTabSecurity {
	width: 100% !important;
	font-size: 13px;
	.profileSetingsContent {
		.settingsPairs {
			border: none;
		}

		.settingsPairPassword {
			margin-top: -9px;
			div {
				height: 16px;
				margin: 12px;
				margin-left: 12px;
				display: flex;
				display: flex;
				align-items: center;
				color: var(--text-primary);
				.icon {
					margin-right: 8px;
				}
				&:first-of-type {
					margin-top: 0;
				}
			}
			.conditionSuccess {
				color: var(--text-positive);
			}
		}
		.settingsButtonsPassword {
			margin-top: 28px;
			width: 100%;
			display: flex;
			gap: 12px;
			.cancelButton,
			.saveButton {
				width: 228px;
			}
		}
	}
}

.versionNumber {
	width: 100%;
	text-align: center;
	height: 16px;
	margin-top: 8px;

	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.31px;
	color: var(--text-secondary);
}

.link {
	color: var(--text-primary); /* //color-dark */
	transition: all 0.3s ease-out;

	&:hover {
		color: var(--text-tertiary);
	}
}

.activateMessage {
	width: 100%;
	font-size: 15px;
	text-align: left;
}

.settingsModalHeader {
	flex-direction: column;
	border: none;
	box-shadow: var(--box-shadow);
	padding: 1rem 1rem 0 1rem !important;
}

.settingsPairsError {
	height: 72px !important;
}

.infoModalWrapperBackground {
	position: absolute;
	width: calc(100% + 240px);
	height: 100%;
	background-color: #000;
	left: -240px;
	top: 0;
	z-index: 10;
	opacity: 0.5;
	border-radius: 19px;
}

.infoModalWrapper {
	width: 333px;
	background-color: var(--surface-low);
	position: absolute;
	top: 140px;
	left: 22px;
	box-shadow: var(--popup-box-shadow);
	border-radius: 24px;
	color: var(--dark);
	z-index: 11;
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 24px 16px 16px 16px;

	h1 {
		font-size: 20px;
		margin: 0;
		font-weight: bold;
	}

	.buttonsContainer {
		display: flex;
		flex-flow: row nowrap;
		gap: 12px;
		justify-content: space-between;

		.button {
			height: 40px;
		}
	}
}

html[dir='rtl'] {
	.platform {
		.rangeContainer {
			.slider {
				padding-left: unset !important;
				padding-right: 12px;
			}
		}

		.checkContainer {
			div {
				padding-right: 8px;
			}
		}
	}

	.passPopup {
		direction: rtl;
	}

	.questionIcon {
		margin-left: 0;
		margin-right: 12px;
	}

	.imgContainer {
		right: -228px !important;
	}
	.closeIcon {
		left: 12px !important;
		right: auto;
	}

	.settingsButtonsPassword {
		justify-content: space-between;
	}

	.saveButton {
		margin-left: unset !important;
		margin-right: unset !important;
	}

	.newPasswordInput {
		padding-right: 44px;
	}

	.settingsPairPassword {
		div {
			margin-left: 0;
			margin-right: 12px;

			.icon {
				margin-left: 8px;
				margin-right: 0px;
			}
		}
	}

	.infoModalWrapperBackground {
		position: absolute;
		left: 0;
	}
	.infoModalWrapper {
		left: unset;
		right: -22px;
	}

	.dropdownMenuLang {
		padding: 12px !important;
	}

	.dropdownIconWrapper {
		margin-right: 0;
		margin-left: 12px !important;
	}
}
