@import 'src/css/common/_custom_scrollbar';
@import 'src/css/common/_modal_header_simple.scss';
@import 'src/css/common/_modal_footer.scss';
@import 'src/css/common/_vertically_center';

.popupMessage {
	padding: 8px;
}

.modalDialog {
	:global {
		.modal-content {
			padding: 0 !important;
			border-radius: 0.3rem !important;
			border-radius: 20px !important;
		}
	}
	.modalHeader {
		@extend %modalHeaderSimple;
		padding: 0 !important;
	}

	.modalBody {
		overflow: auto;
		padding: 0px;
	}

	.container {
		.resizedWrapper {
			padding: 16px;
		}
	}

	.containerUndock {
		flex-grow: 1;
		font-size: var(--fs-15);
		.fieldContainer {
			padding: 12px 0 16px;

			.fieldGroup {
				.row {
					padding: 8px 4px 8px 16px;
				}

				.rowMiddle {
					margin: 8px 4px 16px 16px;

					.row {
						border-bottom: 1px solid var(--border-primary);
						margin: 0px 12px;
						padding: 12px 0;

						&:last-of-type {
							border-bottom: none;
						}
					}
				}
			}
		}

		.fieldTakeProfitStopLoss {
			margin: 0px 4px 0px 16px;
			border: 1px solid var(--border-primary);
			border-radius: 12px;

			.fieldGroup {
				&:first-child {
					border-bottom: 1px solid var(--border-primary);
				}
				.row {
					padding: 12px;
				}
			}
		}
	}
}

.popupMessage {
	padding: 12px;
}

.container {
	display: flex;
	flex-flow: column nowrap;
	height: 100%;
	// max-height: 540px;
	font-size: var(--fs-13);

	.fieldContainer {
		@extend %custom-scrollbar-element;
		overflow-x: hidden;
		overflow-y: auto;
		flex-grow: 1;
		background-color: var(--surface-primary); /* background-color-light */
		:global {
			.ui.selection.active.dropdown {
				&:hover {
					box-shadow: none;
					border-radius: 8px !important;
					background-color: var(--input-secondary) !important;
				}
			}
			.ui.selection.dropdown {
				&:hover {
					box-shadow: none;
					border-radius: 8px !important;
					background-color: var(--input-secondary) !important;
				}
			}
		}

		&.undocked {
			max-height: 400px;
			min-height: 200px;
			flex-grow: 1;
			font-size: var(--fs-15);
		}

		.dropdownMenu {
			height: 32px !important;
			padding: 0 !important;
			min-width: 0px !important;
			min-height: 0px !important;
			border-radius: 8px !important;
			// font-size: 13px !important;
			background-color: var(--input-primary) !important;
			color: var(--text-primary) !important; /* //color-dark */
			display: flex;
			align-items: center;
			justify-content: center;
			border: none !important;

			.menu {
				scrollbar-gutter: auto !important;
			}
		}

		.fieldGroupTitle {
			width: 100%;
			// font-size: 12px;
			color: var(--text-secondary);
			background-color: var(--surface-primary); /* background-color-gray-5 */
			border: 1px solid var(--border-primary);
			height: 35px;
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;
			align-items: center;
			padding: 4px 12px;

			.label {
				flex-grow: 1;
			}

			.control {
				flex-grow: 0;
			}
		}

		.fieldGroup {
			.rowMiddle {
				background-color: var(--surface-secondary);
				margin: 8px 0 8px 12px;
				border-radius: 12px;
				font-size: var(--fs-13);
				.row {
					border-bottom: 1px solid var(--border-primary);
					margin: 0px 12px;
					padding: 12px 0;

					&:last-of-type {
						border-bottom: none;
					}
				}
			}

			.row {
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				// border-bottom: 1px solid var(--border-primary);
				padding: 8px 0px 8px 12px;
				color: var(--text-primary); /* //color-dark */

				.wrapper {
					width: 100%;
					flex-flow: nowrap;
					align-items: center;
				}

				.fieldWrapper {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 8px;
					.numberInputWrapper {
						width: 50%;
					}
				}

				&.noPadding {
					padding: 0;
				}

				&.noBorder {
					border: 0px;
				}

				& > * {
					flex: 1;
					width: 50%;
					display: flex;
					justify-content: space-between;
					flex-flow: column nowrap;
				}

				&.infoRow {
					// background-color: var(--surface-primary); /* background-color-gray-5 */

					.infoLabel {
						display: flex;
						flex-direction: row;
						justify-content: flex-end;
					}
				}

				.label {
					width: 50%;
					color: var(--text-secondary);

					.subLabel {
						color: var(--text-secondary);
					}
				}

				.input {
					position: relative;
					color: var(--text-primary); /* //color-dark */
				}

				.subText {
					display: flex;
					justify-content: flex-end;
					color: var(--text-secondary);

					&.positiveSubText {
						color: var(--text-positive); /* color-positive*/
					}

					&.negativeSubText {
						color: var(--text-negative); /* color-negative*/
					}
				}

				.errorValidation {
					display: flex;
					justify-content: flex-end;
					color: var(--watermelon);
					font-size: 12px;
				}

				// .toggleButton {
				// 	display: flex;
				// 	flex-direction: row;
				// 	justify-content: flex-end;

				// 	label {
				// 		cursor: pointer !important;
				// 	}
				// }

				.toggleButton {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: flex-end;
					// max-width: 20px;
					input {
						width: 20px;
						height: 20px;
					}

					label {
						min-height: 20px;
						padding: 0;
						cursor: pointer !important;
						margin: 0;
						width: 36px;

						&::before {
							width: 32px;
							height: 12px;
							top: 4px;
							left: 4px;
							background-color: var(--surface-secondary);
							&:hover {
								background-color: var(--surface-tertiary);
							}
						}
						&::after {
							width: 20px;
							height: 20px;
							top: 0px;
							left: 0px;
						}
					}
				}

				.toggleButtonChecked {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: flex-end;
					margin: 0;

					input {
						width: 20px;
						height: 20px;
					}

					label {
						min-height: 20px;
						padding: 0;
						cursor: pointer !important;
						margin: 0;
						width: 36px;

						&::before {
							width: 32px;
							height: 12px;
							top: 4px;
							left: 4px;
						}
						&::after {
							width: 20px;
							height: 20px;
							top: 0px;
							left: 20px !important;
						}
					}
				}

				:global {
					.ui.fitted.toggle.checkbox {
						width: 40px;
					}
				}

				.calendarIcon {
					position: absolute;
					z-index: 1;
					cursor: pointer;
					font-size: 10px;
					padding: 7px;
					color: var(--text-primary); /* //color-dark */
				}
			}
		}
	}

	.sideCloseButton {
		@include vertically-center;
	}

	.tradeTicketButton {
		left: -3px;
		z-index: 10;
	}

	.tradersGymField {
		background-color: rgba(248, 99, 99, 0.1);
		padding: 4px;
		// font-size: 11px;
		color: var(--text-primary); /* //color-dark */
	}

	.footer {
		display: flex;
		justify-content: space-between;
		padding: 8px 12px;
		// border-top: 1px solid var(--border-primary);
		z-index: 1;
		gap: 8px;

		& > * {
			width: 100% !important;
			max-width: unset !important;
		}
	}
}

.containerUndock {
	.footer {
		padding: 12px 16px 16px;
		gap: 12px;
	}
}

@-moz-document url-prefix() {
	.row {
		/* Firefox-specific styles */
		color: red; /* Change to your desired Firefox-specific color */
	}
}

@supports (-moz-appearance: none) {
	.container {
		.fieldContainer {
			.fieldGroup {
				.row {
					padding-right: 12px;
				}
			}
		}
	}
}

.dropdownMenu {
	height: 32px !important;
	padding: 0 !important;
	min-width: 0px !important;
	min-height: 0px !important;
	border-radius: 8px !important;
	// font-size: 13px !important;
	background-color: var(--input-primary) !important;
	color: var(--text-primary) !important; /* //color-dark */
	display: flex;
	align-items: center;
	justify-content: center;
	border: none !important;

	.menu {
		scrollbar-gutter: auto !important;
	}
}

.fieldGroupTitle {
	width: 100%;
	// font-size: 12px;
	color: var(--text-secondary);
	background-color: var(--surface-primary); /* background-color-gray-5 */
	border: 1px solid var(--border-primary);
	height: 35px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	padding: 4px 12px;

	.label {
		flex-grow: 1;
	}

	.control {
		flex-grow: 0;
	}
}

html[dir='rtl'] {
	.fieldContainer {
		scrollbar-gutter: inherit !important;
		padding-right: 8px;
	}

	.tradeTicketButton {
		right: -3px !important;
		left: unset !important;

		svg {
			transform: rotate(0deg)!important;
		}
	}
}
